import { Divider, Form, Input, Modal, Popconfirm, Spin, Switch } from "antd";
import { MODULE_KEY, fetchFrameArrangerQty } from "./actions";
import GenericTable from "src/components/common/Table/GenericTable";
import TableFilter from "src/components/common/TableFilter/TableFilter";
import { PlusOutlined } from "@ant-design/icons";
import Breadcrumb from "src/components/common/Breadcrumb/Breadcrumb";
import Button from "src/components/common/Button/Button";
import { useEffect, useState } from "react";
import filterColumns from "./filterColumns";
import AddFAQty from "./AddFAQty";
import "./../../../styles/vendor.scss";
import { useDispatch } from "react-redux";
import { deleteAssortment, getFADetail } from "./sagas";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import Label from "src/components/common/Label/Label";
import { useInputs } from "src/hooks/useInput";
import { useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";
import { ACTION } from "src/util/utils";

function FrameArrangerQty() {
  const defaultData = {};
  const { accounts }: any = useMsal();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalData, setModalData] = useState<any>({});
  const [visible, setVisible] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(false);
  const [frameDetailForm] = Form.useForm();
  const [skuData, setSkuData] = useState<any>([]);
  const [showInactive, setShowInactive] = useState(false);
  const [datasource, setDataSource] = useState([]);
  const [inputs, onInputChange, updateValue, resetInputs] = useInputs({
    defaultData,
  });
  const [actionType, setActionType] = useState("");

  const { data, activeAssortments, inactiveAssortments } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );

  useEffect(() => {
    setDataSource(showInactive ? data : activeAssortments);
  }, [data, showInactive]);

  useEffect(() => {
    setDataSource(activeAssortments);
    dispatch(fetchFrameArrangerQty({}));
  }, []);

  const addDrawer = (e: React.MouseEvent<HTMLButtonElement>) => {
    setModalData({});
    frameDetailForm.resetFields();
    setVisible(true);
  };
  const editDrawer = (record: any, type: string) => {
    let payload;
    payload = record?.AssortmentId;
    setVisible(true);
    const res = getFADetail(payload);
    res.then((response: any) => {
      setActionType(type);
      setModalData(response);
    });
  };
  const deleteModal = (record: any) => {
    setVisibleDelete(true);
    setDeleteData(record);
  };

  const handleCancel = () => {
    dispatch(fetchFrameArrangerQty({}));
    form.resetFields();
    resetInputs();
  };

  let filterValues = filterColumns(editDrawer, deleteModal);
  const handleDelete = () => {
    if (skuData?.length) {
      const found = skuData?.some((item: any) => item.UPC === deleteData);
      if (found) {
        const pos = skuData.map((e: any) => e.UPC).indexOf(deleteData);
        skuData.splice(pos, 1);
        setSkuData([...skuData]);
        showSuccessNotification("SKU(s) deleted successfully");
      }
    } else {
      const payload = {
        ID: deleteData,
        CreatedBy: accounts?.[0]?.username,
      };
      deleteAssortment(payload)
        .then((res: any) => {
          showSuccessNotification(res);
          handleCancel();
        })
        .catch((error: any) => {
          showErrorNotification(error.response.data.error.messages[0]);
        });
    }
    setVisibleDelete(false);
  };

  const handleSkuSearch = () => {
    const payload = {
      SKUs: inputs?.SkuSearch?.split(",")?.map((item: any) => item?.trim()),
    };
    dispatch(fetchFrameArrangerQty(payload));
  };

  const handleResetSearch = () => {
    handleCancel();
  };
  return (
    <>
      <Spin spinning={confirmLoading}>
        <Divider />
        <Breadcrumb>
          <span>Assortment Management</span>
        </Breadcrumb>
        <TableFilter>
          <Form
            layout={"horizontal"}
            form={form}
            initialValues={{ layout: "horizontal" }}
          >
            <div className="form align-items-end">
              <Label label="Search for SKU(s), separated by commas (,)">
                <Input
                  autoComplete="false"
                  name="SkuSearch"
                  value={inputs.SkuSearch}
                  onChange={onInputChange}
                  style={{ width: "400px" }}
                ></Input>
              </Label>
              <Button
                disabled={!inputs?.SkuSearch || confirmLoading}
                onClick={handleSkuSearch}
                size="middle"
              >
                Search
              </Button>
              <Button
                disabled={confirmLoading}
                onClick={handleResetSearch}
                size="middle"
              >
                Reset
              </Button>
            </div>
          </Form>

          <div className="d-flex justify-content-end gap-3">
            {showInactive}
            <Label
              label="Show Inactive"
              className="flex-row align-items-center fs-6 gap-3"
            >
              <Switch
                size="small"
                checked={showInactive}
                onChange={(flag) => {
                  setShowInactive(flag);
                }}
              />
            </Label>
            <Button
              size="middle"
              icon={<PlusOutlined />}
              style={{ marginRight: "10px" }}
              ant-click-animating-without-extra-node="true"
              onClick={addDrawer}
            >
              {" "}
              Add New
            </Button>
          </div>
        </TableFilter>
        <GenericTable
          rowkey="AssortmentId"
          columns={filterValues.columns}
          moduleKey={MODULE_KEY}
          loading={confirmLoading}
          xScroll={1600}
          yScroll={"calc(90vh - 17.5em)"}
          dataSource={datasource}
          pagination={{
            defaultPageSize: 500,
            showSizeChanger: true,
            pageSizeOptions: ["10", "50", "100", "500", "1000"],
          }}
        />{" "}
        <AddFAQty
          visible={visible}
          setVisibleStatus={setVisible}
          onLoad={handleCancel}
          data={modalData}
          setData={setModalData}
          filterValues={filterValues}
          confirmLoading={confirmLoading}
          setConfirmLoading={setConfirmLoading}
          frameDetailForm={frameDetailForm}
          skuData={skuData}
          setSkuData={setSkuData}
          actionType={actionType}
          accounts={accounts}
        />
      </Spin>
      <Modal
        title="Delete Confirmation"
        okText="Ok"
        centered
        confirmLoading={confirmLoading}
        style={{ top: 20 }}
        open={visibleDelete}
        onOk={handleDelete}
        onCancel={() => setVisibleDelete(false)}
      >
        <span>
          Are you sure you wish to delete this{" "}
          {skuData?.length ? "UPC" : "assortment"}?
        </span>
      </Modal>
    </>
  );
}
export default FrameArrangerQty;
