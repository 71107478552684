import { useEffect, useState } from "react";
import { Modal, Table } from "antd";
import styled from "styled-components";
import "../OfficeLocations/officeLocation.scss";
import { ASSORTMENT_SKU_COLUMN } from "src/util/columns";
import { fetchSKUDetails } from "src/components/FrameArranger/FrameArrangerQty/sagas";
import { showErrorNotification } from "src/util/notifications";
import { fetchAssortments } from "src/components/FrameArranger/ProductPlanogram/sagas";

interface Props {
  param: any;
  modalTitle: any;
}

function Links({ param, modalTitle }: Props) {
  const [open, setOpen] = useState(false);
  const [skuData, setSkuData] = useState<any>();

  const handleLink = (id: any) => {
    let res;
    if (modalTitle === "Assortment(s)") {
      res = fetchAssortments(param);
    } else {
      res = fetchSKUDetails(param);
    }
    res
      ?.then((res: any) => {
        if (res?.length) {
          setSkuData(res);
          setOpen(true);
        } else {
          showErrorNotification(`No assortment found for this UPC (${param})`);
        }
      })
      .catch((error: any) => {
        showErrorNotification(error.response.data.error.messages[0]);
      });
  };
  const modelWidth = modalTitle === "Assortment(s)" ? 400 : 800;
  return (
    <>
      {modalTitle === "Assortment(s)" ? (
        <StyledLocation>
          <span onClick={handleLink}>Assortments</span>
        </StyledLocation>
      ) : (
        <StyledLocation>
          <span onClick={handleLink}>SKUs</span>
        </StyledLocation>
      )}

      <Modal
        title={modalTitle}
        okText="Ok"
        centered
        width={modelWidth}
        bodyStyle={{ overflowY: "scroll", maxHeight: "calc(100vh - 200px)" }}
        open={open}
        onCancel={() => setOpen(false)}
        footer={false}
      >
        {" "}
        {modalTitle === "Assortment(s)" ? (
          skuData?.map((item: any) => {
            return <StyledItem>{item}</StyledItem>;
          })
        ) : (
          <Table
            rowKey="UPC"
            columns={ASSORTMENT_SKU_COLUMN}
            dataSource={skuData}
            scroll={{ y: 500 }}
            pagination={false}
          />
        )}
      </Modal>
    </>
  );
}

const StyledLocation = styled.div`
  color: #40668c;
  text-decoration: underline;
  cursor: pointer;
`;

const StyledItem = styled.div`
  align: "center";
`;

export default Links;
