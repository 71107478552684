import { call, put, select } from 'redux-saga/effects';
import { default as api } from '../../util/api';
import { OPEN_PULL_URL } from '../../util/constants/api.constants';
import { fetchOpenPullListFailed, fetchOpenPullListSucces } from './openPull-actions';
import { toQueryParam } from '../../util/utils';
import { showErrorNotification, showSuccessNotification } from '../../util/notifications';

export function* fetchOpenPull({ payload }: any): Generator {
  try {
    const {startDate, endDate, createdBy} = payload;
    let url = toQueryParam(OPEN_PULL_URL.REQUEST_PULL, payload);
    const data: any = yield call(api.get, url);
    yield put(fetchOpenPullListSucces(data));
    if(data.length){
      yield loadOrder({startDate,endDate, createdBy});
      showSuccessNotification("Open order pull successfully completed");
    }
  } catch (e: any) {
    showErrorNotification(null,  e.message );
  }
}

export function loadOrder(payload: any) {
  let url = OPEN_PULL_URL.LOAD_ORDER;
  return api.post(url, payload);
}
