import { ACTION } from "src/util/utils";
import {
  ASSORTMENT_SKU_KEYS,
  ASSORTMENT_SKU_MODULE_KEY,
  CLEAN_IMPORT_LAYOUT,
  IMPORT_FILE_FAILED,
  IMPORT_FILE_SUCCESS,
  IMPORT_SKU_FILE,
  KEYS,
  MODULE_KEY,
  UPDATE_VALIDATED_IMPORT,
  UPDATE_VALIDATED_IMPORT_SUCCESS,
} from "./actions";

export const REDUCER_KEY = KEYS[MODULE_KEY + ACTION.STATE_KEY];
export const ASSORTMENT_SKU_REDUCER_KEY =
  ASSORTMENT_SKU_KEYS[ASSORTMENT_SKU_MODULE_KEY + ACTION.STATE_KEY];

export interface IFrameArrangerQtyState {
  loading: boolean;
  error: any;
  data: [];
  activeAssortments: [],
  inactiveAssortments: [],
}

const defaultState: IFrameArrangerQtyState = {
  loading: false,
  error: null,
  data: [],
  activeAssortments: [],
  inactiveAssortments: [],
};

export const frameArrangerQtyReducer = (
  state = defaultState,
  { type, payload }: any
) => {
  switch (type) {
    case KEYS[MODULE_KEY + ACTION.FETCH]:
      return {
        ...state,
        loading: true,
      };

    case KEYS[MODULE_KEY + ACTION.SUCCESS]:
      return {
        ...state,
        loading: false,
        data: [...payload],
        activeAssortments: [...payload.filter((e: any) => e.Active)],
        inactiveAssortments: [...payload.filter((e: any) => !e.Active)],
      };
    case KEYS[MODULE_KEY + ACTION.FAILED]:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export interface IAssortmentSkuState {
  loading: boolean;
  error: any;
  data: [];
  importLoading: boolean;
  importFailed: boolean;
  importValidationData: any[];
}

const defaultStateAssortmentSku: IAssortmentSkuState = {
  loading: false,
  error: null,
  data: [],
  importLoading: false,
  importFailed: false,
  importValidationData: [],
};

export const assortmentSkuReducer = (
  state = defaultStateAssortmentSku,
  { type, payload }: any
) => {
  switch (type) {
    case ASSORTMENT_SKU_KEYS[ASSORTMENT_SKU_MODULE_KEY + ACTION.FETCH]:
      return {
        ...state,
        loading: true,
      };

    case ASSORTMENT_SKU_KEYS[ASSORTMENT_SKU_MODULE_KEY + ACTION.SUCCESS]:
      return {
        ...state,
        loading: false,
        data: [...payload],
      };
    case ASSORTMENT_SKU_KEYS[ASSORTMENT_SKU_MODULE_KEY + ACTION.FAILED]:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case IMPORT_SKU_FILE: {
      return {
        ...state,
        importLoading: true,
      };
    }
    case IMPORT_FILE_SUCCESS: {
      return {
        ...state,
        importLoading: false,
        importFailed: false,
        importValidationData: payload,
      };
    }
    case IMPORT_FILE_FAILED: {
      return {
        ...state,
        importLoading: false,
        importFailed: true,
        importValidationData: payload,
      };
    }
    case UPDATE_VALIDATED_IMPORT: {
      return {
        ...state,
        importLoading: true,
      };
    }
    case UPDATE_VALIDATED_IMPORT_SUCCESS:
    case CLEAN_IMPORT_LAYOUT: {
      return {
        ...state,
        importLoading: false,
        importFailed: false,
        importValidationData: [],
      };
    }

    default:
      return state;
  }
};
